export default [
  // Dashboard and Member
  // {
  //   header: 'Dashboard and Member',
  //   resource: 'User',
  // },
  // {
  //   title: 'แดชบอร์ด',
  //   route: 'dashboard-partner',
  //   icon: 'icon fad fa-chart-line',
  //   resource: 'User',
  // },
  // {
  //   title: 'รายการฝาก',
  //   route: 'deposit',
  //   icon: 'icon fad fa-comments-alt-dollar',
  //   resource: 'User',
  // },
  // {
  //   title: 'รายการถอน',
  //   route: 'withdraw',
  //   icon: 'icon fad fa-hand-holding-usd',
  //   resource: 'User',
  // },
  // {
  //   title: 'รายการลูกค้า',
  //   route: 'member',
  //   icon: 'icon fad fa-users-crown',
  //   resource: 'User',
  // },
  // {
  //   header: 'ระบบการเพิ่มเอเย่นต์',
  //   resource: 'User',
  // },
  // {
  //   title: 'รายการเอเย่นต์',
  //   route: 'agent-list',
  //   icon: 'icon fad fa-users-crown',
  //   resource: 'User',
  // },
  // {
  //   header: 'ระบบจัดการล็อตเตอรี่',
  //   resource: 'User',
  // },
  // {
  //   title: 'จัดการล็อตเตอรี่',
  //   route: 'generate-lot',
  //   icon: 'icon fad fa-cogs',
  //   resource: 'User',
  // },
  // {
  //   title: 'เปิดรอบล็อตเตอรี่',
  //   route: 'round-open',
  //   icon: 'icon fad fa-tasks',
  //   resource: 'User',
  // },
  {
    header: 'ระบบการสำหรับเอเย่นต์',
    resource: 'User',
  },
  // {
  //   title: 'ซื้อเล่ม',
  //   route: 'seamless-lotto',
  //   icon: 'icon fad fa-comments-alt-dollar',
  //   resource: 'User',
  // },
  {
    title: 'รายการที่ลูกค้าซื้อแล้ว',
    route: 'lottery-list',
    icon: 'icon fad fa-hand-holding-usd',
    resource: 'User',
  },
  {
    title: 'ออกเลขล็อตเตอรี่',
    route: 'reward',
    icon: 'icon fad fa-vote-yea',
    resource: 'User',
  },
  {
    title: 'รายการที่ลูกค้าถูกรางวัล',
    route: 'lottery-list-reward',
    icon: 'icon fad fa-tasks',
    resource: 'User',
  },
  // {
  //   title: 'เปอร์เซ็นต์ SubAgent',
  //   route: 'lottery-log',
  //   icon: 'icon fad fa-tasks',
  //   resource: 'User',
  // },
  // {
  //   title: 'รายงานกำไรขาดทุน',
  //   route: 'winlose-list',
  //   icon: 'icon fad fa-tasks',
  //   resource: 'User',
  // },
  // {
  //   header: 'Invite Friend',
  //   resource: 'User',
  // },
  // {
  //   title: 'แนะนำ (เซียน)',
  //   route: 'invite-friend',
  //   icon: 'icon fad fa-user-friends',
  //   resource: 'User',
  // },
  // {
  //   header: 'Games',
  //   resource: 'User',
  // },
  // {
  //   title: 'กงล้อ',
  //   route: 'lucky-wheel',
  //   icon: 'icon fad fa-dharmachakra',
  //   resource: 'zean',
  // },
  // AG Admin Management
  // {
  //   header: 'AG Admin Management',
  //   resource: 'Agadmin',
  // },
  {
    title: 'รายงาน Win/Lose',
    route: 'report',
    icon: 'icon fad fa-trophy-alt',
    resource: 'Agadmin',
  },
  // {
  //   title: 'ธนาคาร',
  //   route: 'bank',
  //   icon: 'icon fad fa-analytics',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'ช่องทางรู้จัก',
  //   route: 'channel',
  //   icon: 'icon fad fa-ad',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'จัดการบัญชีผู้ใช้',
  //   route: 'admin-management',
  //   icon: 'icon fad fa-address-card',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'จัดการบัญชีเอเย่นต์',
  //   route: 'admin-agent-management',
  //   icon: 'icon fad fa-user-secret',
  //   resource: 'Admin',
  // },
  // {
  //   title: 'โปรโมชั่น',
  //   route: 'promotion',
  //   icon: 'icon fad fa-gift-card',
  //   resource: 'Admin',
  // },
  // {
  //   title: 'จัดการเอเย่นต์',
  //   route: 'setting',
  //   icon: 'icon fad fa-users-cog',
  //   resource: 'Agadmin',
  // },
  // {
  //   title: 'ฝากต่อเนื่อง',
  //   route: 'setting',
  //   icon: 'SettingsIcon',
  //   resource: 'Admin',
  // },

  // SMS Status
  // {
  //   header: 'Error List',
  //   resource: 'User',
  // },
  // {
  //   title: 'รายการฝากไม่สำเร็จ',
  //   route: 'waiting-dep',
  //   icon: 'icon fad fa-exclamation',
  //   resource: 'User',
  // },
  // {
  //   header: 'SMS Status',
  //   resource: 'User',
  // },
  // {
  //   title: 'SMS ฝาก',
  //   route: 'sms-deposit',
  //   icon: 'icon fad fa-comment-alt-dollar',
  //   resource: 'Admin',
  // },
  // {
  //   title: 'SMS ถอน',
  //   route: 'sms-withdraw',
  //   icon: 'icon fad fa-comment-alt-dollar',
  //   resource: 'User',
  // },
  // {
  //   title: 'SMS All',
  //   route: 'sms-all',
  //   icon: 'icon fad fa-comment-alt-dollar',
  //   resource: 'Admin',
  // },
  // {
  //   title: 'SMS OTP',
  //   route: 'sms-otp',
  //   icon: 'icon fad fa-comment-alt-exclamation',
  //   resource: 'User',
  // },

  // {
  //   title: 'True Money Wallet',
  //   route: 'true-money',
  //   icon: 'icon fad fa-comment-alt-lines',
  //   resource: 'User',
  // },
  // {
  //   title: 'KBANK LIVE',
  //   route: 'kbank-live',
  //   icon: 'icon fad fa-university',
  //   resource: 'User',
  // },
  // {
  //   title: 'SCB Connect',
  //   route: 'scb-connect',
  //   icon: 'icon fad fa-university',
  //   resource: 'User',
  // },
  // {
  //   title: 'SCB Statement',
  //   route: 'statement',
  //   icon: 'icon fad fa-money-check',
  //   resource: 'User',
  // },
  // Setting
  // {
  //   header: 'Admin Management',
  //   resource: 'Admin',
  // },
  // {
  //   title: 'Agent List',
  //   route: 'agent-list',
  //   icon: 'SettingsIcon',
  //   resource: 'Admin',
  // },
]
